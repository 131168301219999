import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import style from './mailinglist.module.scss';

const postUrl = `https://drastick.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

const MailingList = () => {
	const [ success, setSuccess ] = useState(false);
	const [ error, setError ] = useState(false);
	const { register, handleSubmit, reset, formState } = useForm({
		EMAIL: '',
		MERGE1: '',
		MERGE2: ''
	});
console.log('formState => ', formState.errors);
	return (
		<div className={style.mailinglist}>
			<MailchimpSubscribe
				url={postUrl}
				render={({ subscribe, status, message }) => {
					const onSubmit = async (data) => {
						console.log('data', data);
						setError(false);
						setSuccess(false);
						await subscribe({
							EMAIL: data.email,
							MERGE1: data.firstName,
							MERGE2: data.lastName
						});
						console.log('status', status);
						console.log('message', message);
						if (status === 'error') {
							setError(message);
							setSuccess(false);
						}
						else if (status === 'success') {
							setSuccess(message);
							setError(false);
						}

						reset();
					};

					const onError = (error) => {
						console.log('error => ', error);
					}
					return (
						<>
							<h2 className={style.desc}>Follow us on social
								<a href="https://www.facebook.com/Pod-City-Beer-Labs-107988405233010"><i className={`bi bi-facebook ${style.facebook}`} /></a>
							{/* <a href="#"><i className={`bi bi-instagram ${style.instagram}`} /></a> */}
							and join our mailing list to stay up to date with our progress!</h2>
							<Form className={style.form} onSubmit={handleSubmit(onSubmit, onError)}>

									<Form.Group className="mb-3" controlId="email">
										<InputGroup>
											<Form.Control size="lg" isInvalid={formState.errors.email?.message} required type="email" placeholder="name@example.com" {...register("email", {
													required: 'Please enter a valid email address', pattern: {
														value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
														message: 'Please enter a valid email address'
													}
												})} />
												<Button size="lg" aria-label="submit" type="submit" variant="outline-primary">
													Submit
												</Button>
										</InputGroup>
									<Form.Control.Feedback type="invalid">{formState.errors.email?.message}</Form.Control.Feedback>
									{success && <Form.Control.Feedback>{success}</Form.Control.Feedback>}
									{error && <Form.Control.Feedback className={style.invalid} type="invalid">{error}</Form.Control.Feedback>}
									</Form.Group>

								{/* <Form.Group className="mb-3" controlId="firstname">
									<Form.Label>First</Form.Label>
									<Form.Control isInvalid={formState.errors.firstName?.message} as="input" {...register("firstName", { required: 'Please enter your first name' })} />
									<Form.Control.Feedback type="invalid">{formState.errors.firstName?.message}</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-3" controlId="lastname">
									<Form.Label>Last Name</Form.Label>
									<Form.Control isInvalid={formState.errors.lastName?.message} as="input" {...register("lastName", { required: 'Please enter your last name' })} />
									<Form.Control.Feedback type="invalid">{formState.errors.lastName?.message}</Form.Control.Feedback>
								</Form.Group> */}
								{/* <Button aria-label="submit" type="submit" variant="outline-primary">Submit</Button> */}
							</Form>
						</>
					);
				}} />
		</div>
	);
};

export default MailingList;