import { useEffect, useState } from 'react';

import Untappd from '../../components/untappd';
import Hero from '../../components/hero';
import GlobalNav from '../../components/global-nav';
import Footer from '../../components/footer';


import style from './home.module.scss';
import MailingList from '../../components/mailing-list';
import { Parallax } from 'react-scroll-parallax';

const Home = () => {
	const [ progress, setProgress ] = useState(0);

	return (
		<>
			{/* <Parallax speed={-50}> */}
				<Hero progress={progress} />
			{/* </Parallax> */}
			{/* <Parallax style={{ zIndex: 2, position: 'relative' }} onProgressChange={setProgress} speed={0}>
				<Untappd />

			</Parallax> */}
		</>
	);
};

export default Home;