import { useEffect, useState, useRef } from 'react';
import img from '../../assets/images/labstuff.png';
import smoke from '../../assets/images/smoke.jpg';
import podweb from '../../assets/images/podweb.jpg';
import logo from '../../assets/images/logo-flat.png';
import style from './hero.module.scss';
import Footer from '../footer';
import MailingList from '../mailing-list';

const checkChildren = (children, state) => {
	for (const child in children) {
		if (children[child]?.className?.includes('bubble') && children[child]?.style?.animationPlayState !== state) {
			children[child].style.animationPlayState = state;
		}
	}
}

const Hero = ({ progress }) => {
	const el = useRef();
	const children = el?.current?.children;

	const comingSoon = (process.env.REACT_APP_COMING_SOON === 'true');

	if (progress > .2 && children) {
		checkChildren(children, 'paused');

	}
	else if (children) {
		checkChildren(children, 'running');
	}

	return (
		<div className={`${style.hero} ${comingSoon ? style.comingsoon : ''}`} style={ !comingSoon ? { backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)),url(${podweb})` } : {}}>
			{!comingSoon && <div ref={el} className="beaker">
				<div className={style.bubblecont}>
					<img className={style.logo} src={logo} alt="" />
					<div className="bubble x1"></div>
					<div className="bubble x2"></div>
					<div className="bubble x3"></div>
					<div className="bubble x4"></div>
					<div className="bubble x5"></div>
				</div>
			</div>}
			<div className={style.list}>
				<div className={style.listinner}>
					<h1 className="display-1">Something is <span>Brewing</span></h1>
					{ !comingSoon && <MailingList /> }
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Hero;