import { useState, useEffect } from 'react';
import debounce from 'debounce';

export const SIZE_XS = 'xs';
export const SIZE_SM = 'sm';
export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';
export const SIZE_XL = 'xl';
export const SIZE_XXL = 'xxl';

const resolveBreakpoint = (width) => {
	if (width < 576) {
		return {
			name: SIZE_XS,
			width: 768
		}
	} else if (width >= 576 && width < 768) {
		return {
			name: SIZE_SM,
			width: 768
		}
	} else if (width >= 768 && width < 992) {
		return {
			name: SIZE_MD,
			width: 1200
		}
	} else if (width >= 992 && width < 1200) {
		return {
			name: SIZE_LG,
			width: 1200
		}
	} else if (width >= 1200 && width < 1400) {
		return {
			name: SIZE_XL,
			width: 1400
		}
	} else if (width >= 1400) {
		return {
			name: SIZE_XXL,
			width: 1800
		}
	}
};

/**
 * @returns {Breakpoint}
 */
const useBreakpoint = () => {
	const [breakpoint, setBreakpoint] = useState(() => resolveBreakpoint(window.innerWidth));

	useEffect(() => {
		const calcBreakpoint = debounce(function () {
			setBreakpoint(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcBreakpoint);
		return () => window.removeEventListener('resize', calcBreakpoint);
	}, []);

	return breakpoint;
};

export default useBreakpoint;