import { Route, BrowserRouter, Routes } from "react-router-dom";

import Home from "./routes/home";
import Food from "./routes/food";
import GlobalNav from "./components/global-nav";
import Footer from "./components/footer";

import './styles/index.scss';
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
	return (
		<BrowserRouter>
			<div className="app">
				<ParallaxProvider>
					{/* <GlobalNav /> */}
					<Routes>
						<Route path="/">
							<Route index element={<Home />} />
							<Route path="food" element={<Food />} />
						</Route>
					</Routes>
					{/* <Footer /> */}
				</ParallaxProvider>
			</div>
		</BrowserRouter>
	);
}

export default App;
