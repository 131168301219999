import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import useBreakpoint from '../../hooks/useBreakpoint';

import style from './food.module.scss';

const Food = () => {
	const breakpoint = useBreakpoint(window.innerWidth);
	const images = [
		`https://images.squarespace-cdn.com/content/v1/5e0b5f4aa9c62214e8043389/1620345646411-7DYOFW17AETMABAEQEBL/canalside+menu+A+2021.jpg?format=${breakpoint.width}w`,
		`https://images.squarespace-cdn.com/content/v1/5e0b5f4aa9c62214e8043389/1620345670563-4K05P99914A04YT9IUDY/canalside+menu+B+2021+.jpg?format=${breakpoint.width}w`
	];

	return (
		<div className={style.food}>
			<Container>
				{
					images.map((image, i) => (
						<Row key={i}>
							<Col>
								<Image fluid src={image} />
							</Col>
						</Row>
					))
				}
			</Container>
		</div>
	);
};

export default Food;