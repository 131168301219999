import { useEffect, useState } from 'react';
import style from './footer.module.scss';

const Footer = () => {

	return (
		<div className={style.footer}>&copy; {new Date().getFullYear() } Pod City Beer Labs</div>
	);
};

export default Footer;